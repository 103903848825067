
function Cars() {
  this.cars = [];
}

Cars.prototype.init = function() {
  let self = this;

  function reportWindowSize() {
    self.updateView();
  }

  window.onresize = reportWindowSize;
}

Cars.prototype.removeAll = function() {
  this.cars = [];

  // Remove any old cars
  $('.car').remove();
  $('.carTag').remove();
};

Cars.prototype.syncCars = function(carData) {
  let self = this;

  $('.car').each(function() {
    const uuid = $(this).attr('uuid');
    const indexUuuid = self.cars.findIndex(x => x.uuid === uuid);

    if (indexUuuid < 0) {
      $(this).remove();
    }
  });
  $('.carTag').each(function() {
    const uuid = $(this).attr('uuid');
    const indexUuuid = self.cars.findIndex(x => x.uuid === uuid);

    if (indexUuuid < 0) {
      $(this).remove();
    }
  });

  let changes = false;

  for (let i = 0; i < carData.length; ++i) {
    const indexUuuid = self.cars.findIndex(x => x.uuid === carData[i].uuid);

    if (indexUuuid < 0) {
      changes = true;
      $('body').append('<div uuid="' + carData[i].uuid + '" id="car' + carData[i].uuid + '" class="car car' + carData[i].carNum + '"></div>');
      $('body').append('<div uuid="' + carData[i].uuid + '" id="carTag' + carData[i].uuid + '" class="carTag">' + carData[i].name + '</div>');
    }
  }

  if (changes) {
    self.updateView();
  }
};

Cars.prototype.updateName = function(uuid, name) {
  let self = this;

  for (let i = 0; i < self.cars.length; ++i) {
    if (self.cars[i].uuid===uuid) self.cars[i].name=name;
  }

  self.updateView();
};

Cars.prototype.update = function(carData) {
  let self = this;

  self.syncCars(carData);

  self.cars = carData;
  self.cars.forEach(x => x.moved = true);

  self.updateView();
}


Cars.prototype.updateView = function() {
  let self = this;
  let carMarginPct = 0.05;
  let heightWidth = 2307 / 7038;
  let viewportWidth = window.innerWidth;
  let viewportWidthMinusCarAndMargin = window.innerWidth - 128 - (viewportWidth * carMarginPct) * 2;
  let backgroundHeight = (viewportWidth * heightWidth);
  let bottomCarY2 = 0.05;
  let bottomCarY1 = 0.24;
  let carSpaceY = [
      backgroundHeight * (bottomCarY1 + 0.08),
      backgroundHeight * (bottomCarY2 + 0.08),
    ]

  if (self.cars.length > 2) {
    carSpaceY = [
      backgroundHeight * (bottomCarY1 + 0.08),
      backgroundHeight * bottomCarY1,
      backgroundHeight * (bottomCarY2 + 0.08),
      backgroundHeight * bottomCarY2,
    ]
  }

  for (let i = 0; i < self.cars.length; ++i) {
    let bottom = carSpaceY[i];
    let left = viewportWidth * carMarginPct + viewportWidthMinusCarAndMargin * self.cars[i].pct;
    let carx = {
        "bottom": bottom + "px",
        "left": left + "px"
      };    
    let carTagx = {
        "bottom": (bottom + 16) + "px",
        "left": left - $('#carTag' + self.cars[i].uuid).width() - 8 + "px"
      };
    if ((self.cars[i].pct) > 0 && self.cars[i].hasOwnProperty('moved')) {
      delete self.cars[i].moved;
      $('#car' + self.cars[i].uuid).stop(true, false).animate(carx, 1000);
      $('#carTag' + self.cars[i].uuid).stop(true, false).animate(carTagx, 1000);
    }
    else {
      $('#car' + self.cars[i].uuid).css(carx);
      $('#carTag' + self.cars[i].uuid).css(carTagx).text(self.cars[i].name);
    }
    $('#car' + self.cars[i].uuid).removeClass('car0 car1 car2 car3 car4 car5 car6 car0 car8 car9 car10 car11 car12 car13').addClass('car' + self.cars[i].carNum);
  }

  $('.flag').css("bottom", backgroundHeight * 0.45 + "px");
  // https://www.cleanpng.com/png-formula-one-race-track-racing-flags-auto-racing-di-172379/
  let desiredBackgroundHeightNormal = 500;
  let backgroundRatio = backgroundHeight / desiredBackgroundHeightNormal;
  let desiredWidthFlagNormal = 64;
  $('.flag').css("width", backgroundRatio * desiredWidthFlagNormal + "px");
  $('.flag').css("height", "auto");

  let desiredWidthCarNormal = 128;
  let desiredHeightCarNormal = 64;
  $('.car').css("width", backgroundRatio * desiredWidthCarNormal  + "px");
  $('.car').css("height", backgroundRatio * desiredHeightCarNormal  + "px");
}

var cars = new Cars();

cars.init();


export default cars;


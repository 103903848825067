
import cookie from './cookie';
import config from './clientServerConfig.json';
import dayjs from 'dayjs';
import sampleText from './sampleText';
import names from './names';
import strings from './strings';

let STATE = {
  play: 'play',
  practiceFinished: 'practiceFinished',
  friendWait: 'friendWait',
  friendFinished: 'friendFinished',
  raceWait: 'raceWait',
  raceFinished: 'raceFinished',
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function AppData() {

}

AppData.prototype.init = function(options) {
  var self = this;

  options = options ? options : {};
  options.watch = options.watch ? options.watch : {};
  options.computed = options.computed ? options.computed : {};
  options.methods = options.methods ? options.methods : {};
  options.mounted = options.mounted ? options.mounted : function() {};

  var vue = {
    el: '#app',
    data: {
      dialog: {
        info: {
          button2: null,
          message: "",
          show: false,
          title: ""
        },
        join: {
          show: false,
        },
      },
      editName: false,
      elapsed: {
        start: 0,
        value: 0,
        text: '00:00',
        limit: 0
      },
      isCPM: false,
      isGameOwner: false,
      joinCode: '',
      languages: [
        {
          code: 'en',
          description: 'English'
        },
        {
          code: 'zh',
          description: 'Chinese (中文)'
        },
        {
          code: 'fr',
          description: 'French (français)'
        },
        {
          code: 'de',
          description: 'German (Deutsche)'
        },
        {
          code: 'hi',
          description: 'Hindi (हिन्दी)'
        },
        {
          code: 'ko',
          description: 'Korean (한국어)'
        },
        {
          code: 'es',
          description: 'Spanish (español)'
        },
      ],
      mathCategories: [
        {
          code: 'add0005',
          description: 'Addition 0 to 5'
        },
        {
          code: 'add0010',
          description: 'Addition 0 to 10'
        },
        {
          code: 'sub0005',
          description: 'Subtraction 0 to 5'
        },
        {
          code: 'sub0010',
          description: 'Subtraction 0 to 10'
        },
      ],
      mathItems: [
        { type: 'add', numbers: [ 5, 9] },
        { type: 'add', numbers: [ 5, 9] },
        { type: 'add', numbers: [ 5, 9] },
        { type: 'add', numbers: [ 5, 9] },
        { type: 'add', numbers: [ 5, 9] },
        { type: 'add', numbers: [ 5, 9] },
        { type: 'add', numbers: [ 5, 9] },
        { type: 'add', numbers: [ 5, 9] },
        { type: 'add', numbers: [ 5, 9] },
        { type: 'add', numbers: [ 5, 9] },
      ],
      pageloaderVisible: false,
      pageloaderText: 'Loading',
      playState: STATE.practicePlay,
      playMode: 'practice',
      strings: strings,
      timerVals: [
        {
          code: '1',
          description: "1 minute timer"
        },
        {
          code: '2',
          description: "2 minute timer"
        },
        {
          code: '3',
          description: "3 minute timer"
        },
        {
          code: '5',
          description: "5 minute timer"
        },
        {
          code: '10',
          description: "10 minute timer"
        },
        {
          code: 's5',
          description: "5 second timer"
        },
        {
          code: 'none',
          description: "No time limit"
        },
      ],
      totalCharacterCount: 0,
      typingLanguages: Object.keys(sampleText.allLanguages).map(x => {
        return {
          code: x,
          description: sampleText.allLanguages[x].description
        };
      }),
      typingInput: '',
      words: [],
      wpmCharsCorrect: 0
    },
    watch: options.watch,
    computed: options.computed,
    methods: options.methods,
    mounted: options.mounted
  };

  this.language = navigator.languages && navigator.languages[0] ||
                navigator.language ||
                navigator.userLanguage || 'en';

  this.language = this.language.substr(0, 2);

  // Default to English first
  let initialLanguage = 'en';
  let foundLanguage = false;

  // If Chrome-like multiple langauges, then find the first language we support
  if (navigator.languages && navigator.languages[0]) {
    for (let l = 0; l < navigator.languages.length; ++l) {
      let supportedLanguage = ['en', 'es', 'de', 'fr', 'ko', 'zh', 'hi'].includes(navigator.languages[l].substr(0, 2));

      if (supportedLanguage) {
        initialLanguage = navigator.languages[l].substr(0, 2);
        foundLanguage = true;
        break;
      }
    }
  }

  if (!foundLanguage && navigator.language && ['en', 'es', 'de', 'fr', 'ko', 'zh', 'hi'].includes(navigator.language.substr(0, 2))) {
    initialLanguage = navigator.language.substr(0, 2);
    foundLanguage = true;
  }

  if (!foundLanguage && navigator.userLanguage && ['en', 'es', 'de', 'fr', 'ko', 'zh', 'hi'].includes(navigator.userLanguage.substr(0, 2))) {
    initialLanguage = navigator.userLanguage.substr(0, 2);
    foundLanguage = true;
  }

  vue.data.saved = {
    language: vue.data.languages.find(x => {
      return x.code === initialLanguage;
    }),
    math: {
      category: vue.data.mathCategories[0]
    },
    name: names.getName(),
    timerVal: 'none',
    typingLanguage: {
      code: 'en',
      description: 'English'
    },
    uuid: uuidv4(),
    wpmLevel: 10,
    wpmHistory: []
  };
  vue.data.save = function() {
    window.localStorage.setItem('savedVueData', JSON.stringify(self.vm.saved));
  };
  vue.data.setLocale = function(languageCode) {
    this.saved.language.code = languageCode;
    this.strings.setLocale(languageCode);
    for (let i = 0; i < this.timerVals.length; ++i) {
      this.timerVals[i].description = this.strings.get('timeLimit' + this.timerVals[i].code);
    }
  };

  var savedVueData = window.localStorage.getItem('savedVueData');

  if (savedVueData) {
    vue.data.saved = $.extend(true, {}, vue.data.saved, JSON.parse(savedVueData));

    // Support old browser data from previous version
    if (typeof vue.data.saved.timerVal !== 'string') {
      vue.data.saved.timerVal = 'none';
    }

    let oldLangCode = {
      english: 'en',
      korean: 'ko',
      spanish: 'es',
      chinese: 'zh',
      french: 'fr',
      hindi: 'hi',
    };

    if (oldLangCode.hasOwnProperty(vue.data.saved.typingLanguage.code)) {
      vue.data.saved.typingLanguage.code = oldLangCode[vue.data.saved.typingLanguage.code];
    }
  }

  vue.data.setLocale(vue.data.saved.language.code);

  this.vm = new Vue(vue);
};

var appData = new AppData();

export { STATE };

export default appData;

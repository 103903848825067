var jquery = require("/js/lib/jquery");
import 'babel-polyfill';
import sampleText from './sampleText';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import Dialog from './dialog';
import debounce from './debounce';

window.$ = window.jQuery = jquery;

import cookie from './cookie';
import config from './clientServerConfig.json';
import wsCall, { wsCallAsync } from './wsCall';
import appData, { STATE } from './appData';
import names from './names';
import cars from './cars';

function App() {
  var self = this;

  self.currentWord = 0;
  self.isSpaceless = false;
}

App.prototype.init = async function() {
  var self = this;

  appData.init({
    watch: {
    },
    computed: {
      startButtonShowStart: function() {
        return (this.playMode === 'friend') && this.isGameOwner && [STATE.friendWait].includes(this.playState);
      },
      startButtonShowRestart: function() {
        return (this.playMode === 'practice') || ((this.playMode === 'friend')  && this.isGameOwner && ![STATE.friendWait].includes(this.playState));
      },
      isTimer: function() {
        return (this.playMode === 'practice') && (this.saved.timerVal!=='none');
      },
      advertText: function() {
        let ret = '';/*
        switch (this.saved.typingLanguage.code) {
          case 'ko':
            if (window.gtag) window.gtag('event', 'Advert', {
              'event_category' : 'Amazon',
              'event_label' : 'ko'
            });
            ret = 'You may need a <a class="linkWhite" target="_blank" href="https://amzn.to/3nhOI9E">Korean keyboard</a> to type in Hangul.';
            break;
          case 'zh':
            if (window.gtag) window.gtag('event', 'Advert', {
              'event_category' : 'Amazon',
              'event_label' : 'zh'
            });
            ret = 'Spend <a class="linkWhite" target="_blank" href="https://amzn.to/37dswbd">30 minutes</a> per day to learn Chinese.';
            break;
          case 'es':
            if (window.gtag) window.gtag('event', 'Advert', {
              'event_category' : 'Amazon',
              'event_label' : 'es'
            });
            ret = 'You may want a <a class="linkWhite" target="_blank" href="https://amzn.to/3neOIYg">Spanish keyboard</a>.';
            break;
          case 'de':
            if (window.gtag) window.gtag('event', 'Advert', {
              'event_category' : 'Amazon',
              'event_label' : 'de'
            });
            ret = 'You may want a <a class="linkWhite" target="_blank" href="https://amzn.to/3njTZhg">German keyboard</a>.';
            break;
          case 'fr':
            if (window.gtag) window.gtag('event', 'Advert', {
              'event_category' : 'Amazon',
              'event_label' : 'fr'
            });
            ret = 'You may want a <a class="linkWhite" target="_blank" href="https://amzn.to/37cqpVg">French keyboard</a>.';
            break;
          case 'hi':
            if (window.gtag) window.gtag('event', 'Advert', {
              'event_category' : 'Amazon',
              'event_label' : 'hi'
            });
            ret = 'Spend <a class="linkWhite" target="_blank" href="https://amzn.to/2WdgIzm">30 minutes</a> per day to learn Hindi.';
            break;
        }*/

        return ret;
      }
    },
    methods: {
      getWPM: function () {
        // https://www.quora.com/How-do-you-calculate-typing-speed#:~:text=Words%20Per%20Minute%20(WPM)%20is,This%20includes%20spaces%20and%20punctuation.
        // WPM calculation is actually not words but rather chars per minute / 5 or with Chinese just chars per minute
        let elapsed = Date.now() - this.elapsed.start;
        let minutes = elapsed / 1000 / 60;
        let charsPerMinute = this.wpmCharsCorrect / minutes;
        return this.isCPM ? Math.floor(charsPerMinute) : Math.floor(charsPerMinute / 5);
      },
      selectLanguage: function (item) {
        this.saved.language = item;
        this.save();
        this.setLocale(this.saved.language.code);
        self.updateTypingInput();
        this.$forceUpdate();
        if (window.gtag) window.gtag('event', 'Select', {
          'event_category' : 'Language',
          'event_label' : this.saved.language.description
        });
      },
      selectTypingLanguage: function (item) {
        this.saved.typingLanguage = item;
        this.playMode = 'practice';
        this.save();
        self.readyToPlay();
        this.$forceUpdate();
        if (window.gtag) window.gtag('event', 'Select', {
          'event_category' : 'TypingLanguage',
          'event_label' : this.saved.typingLanguage.description
        });
      },
      selectPlayMode: function (x) {
        this.playMode = x;
        self.readyToPlay();
        this.$forceUpdate();
      },
      selectTimer: function (x) {
        this.saved.timerVal = x.code;
        this.save();
        self.readyToPlay();
        this.$forceUpdate();
      },
      selectEditName: function () {
        appData.vm.editName = true;
        Vue.nextTick(function() {
          $('#nickName').focus().select();
        });
        $(document)
        $('#nickName').off().on('blur', function() {
          appData.vm.editName = false;
          appData.vm.save();
          cars.updateName(appData.vm.saved.uuid, appData.vm.saved.name);
        }).on('keypress',function(e) {
          if(e.which == 13) {
            appData.vm.editName = false;
            appData.vm.save();
            cars.updateName(appData.vm.saved.uuid, appData.vm.saved.name);
          }
        });
        this.$forceUpdate();
      },
      restart: function () {
        if (window.gtag) window.gtag('event', 'Start', {
          'event_category' : 'Restart',
          'event_label' : this.playMode
        });
        if (this.playMode === 'friend') {
          self.sendRestart();
        }
        else {
          self.readyToPlay();
          self.startPlay();
          this.$forceUpdate();
        }
      },
      start: function () {
        if (window.gtag) window.gtag('event', 'Start', {
          'event_category' : 'Start',
          'event_label' : this.playMode
        });
        if ([STATE.friendWait].includes(this.playState)) {
          self.sendStart();
        }
        else {
          self.startPlay();
          this.$forceUpdate();
        }
      },
      friendGame: function () {
        if (appData.vm.joinCode === '') {
          self.newFriendGame();
        }
        else {
          self.joinFriendGame(appData.vm.joinCode);
        }
      }
    }
  });

  $(document).on('click', '.dropdown', function(e) {
    let hasClass = $(e.currentTarget).children('.dropdownContent').hasClass('dropdownShow');
    $('.dropdownContent').removeClass('dropdownShow');
    if (!hasClass) {
      $(e.currentTarget).children('.dropdownContent').addClass('dropdownShow');
    }
  });

  // Save nick name
  appData.vm.save();

  $(document).on('click', '#startButton', function() {
  });

  self.readyToPlay();

  $(document).on('blur', '#typing', function(e) {
    if (!e || !e.relatedTarget || !['nickName'].includes(e.relatedTarget.id)) {
      setTimeout(function() { $('#typing').focus(); }, 10);
    }
  });

  $(document).on('keyup', '#typing', function(event) {
    App.prototype.keyup(self, event);
  });

  let url = getURL();

  switch (url) {
    case 'race':
      appData.vm.selectPlayMode('friend');
      break;
  }
};

function getURL() {
  var parts = window.location.pathname.replace(/\/$/, "").split('/');
  var currentURL = parts[parts.length - 1];

  currentURL = currentURL.replace(/#+$/, '');
  history.replaceState(null, null, '/');

  return currentURL;
}

function zeroPad(num, places) {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

function toHHMMSS(timePeriodMS) {
  var ms = timePeriodMS;
  var sec = Math.floor(ms / 1000);
  var min = Math.floor(sec / 60) - Math.floor(sec / 3600) * 60;
  var hours  = Math.floor(sec / 3600);

  if (hours !== 0) {
    return zeroPad(hours % 60, 2)+':'+zeroPad(min, 2)+':'+zeroPad(sec % 60, 2);
  }
  else {
    return zeroPad(min, 2)+':'+zeroPad(sec % 60, 2);
  }
}

App.prototype.newFriendGame = async function() {
  let self = this;

  try {
    let result = await wsCallAsync({
      url: '/friend/game/new',
      data: {
        wpmLevel: appData.vm.saved.wpmLevel,
        gameText: sampleText.getRandomSentence(appData.vm.saved.typingLanguage.code),
        name: appData.vm.saved.name,
        carNum: self.carNum,
        uuid: appData.vm.saved.uuid,
      }
    });
    try { self.handleClientEvent(result.data.data.clientEvent); } catch (e) {}
    self.openClientEvent(result.data.joinCode);
    appData.vm.joinCode = result.data.joinCode;
    self.updateSampleText(result.data.gameText);
    await Dialog.showInfoAsync(appData.vm.strings.get('giveYourFriend', {joinCode: result.data.joinCode}));
    tempTippy('#joinCode', appData.vm.strings.get('giveYourFriend', {joinCode: result.data.joinCode}), 3000);
    setTimeout(function() { 
      tempTippy('#startButton', appData.vm.strings.get('startTheRace'), 10000);
    }, 3000);
    appData.vm.playState = STATE.friendWait;
    appData.vm.isGameOwner = true;
  }
  catch (result) {
  }
};


App.prototype.sendStart = async function() {
  let self = this;

  try {
    let result = await wsCallAsync({
      url: '/game/start',
      data: {
        joinCode: appData.vm.joinCode,
      }
    });
  }
  catch (result) {
  }
};

App.prototype.sendRestart = async function() {
  let self = this;

  try {
    let result = await wsCallAsync({
      url: '/game/restart',
      data: {
        joinCode: appData.vm.joinCode,
        gameText: sampleText.getRandomSentence(appData.vm.saved.typingLanguage.code),
      }
    });
  }
  catch (result) {
  }
};

App.prototype.closeClientEvent = function() {
  if (self.evtSource) {
    self.evtSource.close();
    self.evtSource = null;
  }
};

App.prototype.handleClientEvent = function(message) {
  let self = this;

  switch (message.eventType) {
  case 'playerUpdate':
    cars.update(message.data);
    break;
  case 'start':
    self.startPlay();
    appData.vm.$forceUpdate();
    tempTippy('#typing', appData.vm.strings.get('startTyping'), 1000);
    break;
  case 'restart':
    self.resetGame();
    self.updateSampleText(message.data.gameText);
    self.updatePercent(0); // Causes cars to change
    self.startPlay();
    appData.vm.$forceUpdate();
    tempTippy('#typing', appData.vm.strings.get('startTyping'), 1000);
    break;
  }
};

App.prototype.openClientEvent = function(joinCode) {
  let self = this;
  var urlWS = config.env[process.env.APPENV].urlWS;
  var wsPrefix = config.env[process.env.APPENV].wsPrefix;
  // reconnectFrequencySeconds doubles every retry
  var reconnectFrequencySeconds = 1;

  self.closeClientEvent();
  
  var reconnectFunc = debounce(function() {
    setupEventSource();
    // Double every attempt to avoid overwhelming server
    reconnectFrequencySeconds *= 2;
    // Max out at ~1 minute as a compromise between user experience and server load
    if (reconnectFrequencySeconds >= 64) {
      reconnectFrequencySeconds = 64;
    }
  }, function() { return reconnectFrequencySeconds * 1000; });

  function setupEventSource() {
    self.evtSource = new EventSource(urlWS + wsPrefix + '/clientEvent?joinCode=' + encodeURI(joinCode), {withCredentials: true}); 
    self.evtSource.onmessage = function(e) {
      var json = JSON.parse(e.data);
      
      self.handleClientEvent(json);
    };
    self.evtSource.onopen = function(e) {
      // Reset reconnect frequency upon successful connection
      reconnectFrequencySeconds = 1;
    };
    self.evtSource.onerror = function(e) {
      self.evtSource.close();
      reconnectFunc();
    };
  }
  setupEventSource();
};

App.prototype.joinFriendGame = async function(joinCode) {
  let self = this;

  try {
    let result = await wsCallAsync({
      url: '/friend/game/join',
      data: {
        joinCode: joinCode,
        name: appData.vm.saved.name,
        carNum: self.carNum,
        uuid: appData.vm.saved.uuid,
      }
    });
    if (result.data.joinResult === 'invalid') {
      appData.vm.playMode = 'practice';
      self.readyToPlay();
      self.startPlay();
      appData.vm.dialog.info.message = 'Join code ' + joinCode + ' is incorrect.';
      appData.vm.dialog.info.show = true;
    }
    else if (result.data.joinResult === 'full') {
      appData.vm.playMode = 'practice';
      self.readyToPlay();
      self.startPlay();
      appData.vm.dialog.info.message = 'The game is full for join code ' + joinCode;
      appData.vm.dialog.info.show = true;
    }
    else {
      appData.vm.joinCode = result.data.joinCode;
      try { self.handleClientEvent(result.data.data.clientEvent); } catch (e) {}
      self.openClientEvent(result.data.joinCode);
      self.updateSampleText(result.data.gameText);
      appData.vm.dialog.info.title = '';
      appData.vm.dialog.info.message = appData.vm.strings.get('startTypingCounter');
      appData.vm.dialog.info.show = true;
      tempTippy('#typing', appData.vm.strings.get('startTypingCounter'), 10000);
      appData.vm.playState = STATE.friendWait;
    }
  }
  catch (result) {
  }
};
  
App.prototype.splitWords = function(text) {
  let self = this;

  if (['zh'].includes(appData.vm.saved.typingLanguage.code)) {
    // https://stackoverflow.com/a/43743634/5086812
    // http://flyingsky.github.io/2018/01/26/javascript-detect-chinese-japanese/
    // https://stackoverflow.com/a/51941287/5086812
    // https://medium.com/@rossbulat/iterating-strings-with-regex-in-javascript-6652f9efa52f
    // Finally, this worked, and it should work for other unicode languages too
    // https://stackoverflow.com/a/59690880/5086812
    let characters = [...text];

    self.isSpaceless = true;
    appData.vm.isCPM = true;

    return characters;
  }
  else {
    self.isSpaceless = false;
    appData.vm.isCPM = false;
    return text.split(' ');
  }
}

App.prototype.updateSampleText = function(text) {
  let self = this;
  
  // Replace double-spaces with single
  text = text.replace(/\s\s+/g, ' ');
  // Split into individual words or characters for Chinese
  appData.vm.words = self.splitWords(text);
  // Get total character count
  appData.vm.totalCharacterCount = 0;
  appData.vm.words.forEach(x => appData.vm.totalCharacterCount += x.length);

  $('#sampleText').text('');
  for (let i = 0; i < appData.vm.words.length; ++i) {
    let textHTML = '<span id="word' + i + '" class="word">' + appData.vm.words[i]+ '</span>';

    if (!['zh'].includes(appData.vm.saved.typingLanguage.code)) {
      textHTML += ' ';
    }
    $('#sampleText').append(textHTML);
  }
};

// https://stackoverflow.com/a/2450976/5086812
function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

App.prototype.resetGame = function() {
  let self = this;

  self.carNum = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13])[0];
  self.updateSampleText(sampleText.getRandomSentence(appData.vm.saved.typingLanguage.code));
  self.currentWord = 0;
  appData.vm.wpmCharsCorrect = 0;
  clearInterval(self.timerPlay);
  appData.vm.elapsed.value = 0;
  appData.vm.elapsed.text = '00:00';
  $('.currentword').removeClass('currentword');
  $('.word-incorrect').removeClass('word-incorrect');
  $('.word-correct').removeClass('word-correct');
  $('#word0').addClass('currentword');
  $('#typing').val('');
};

App.prototype.readyToPlay = function() {
  let self = this;

  self.resetGame();
  cars.removeAll();
  appData.vm.isGameOwner = false;


  if (appData.vm.playMode === 'practice') {
    appData.vm.joinCode = '';
    cars.update([{
      name: appData.vm.saved.name,
      carNum: self.carNum,
      uuid: appData.vm.saved.uuid,
      pct: 0
    }]);
    self.startPlay();
    tempTippy('#typing', appData.vm.strings.get('startTyping'), 1000);
  }
  else if (['friend'].includes(appData.vm.playMode)) {
    appData.vm.playState = STATE.friendWait;
    self.updateTypingInput();

    appData.vm.joinCode = '';
    appData.vm.dialog.join.show = true;
    Vue.nextTick(function() {
      $('#joinCodeInput').focus();
    });
  }
  else if (['race'].includes(appData.vm.playMode)) {
    appData.vm.joinCode = '';
    appData.vm.playState = STATE.raceWait;
    self.updateTypingInput();
  }
};

App.prototype.updateTypingInput = function() {
  if (appData.vm.playState === STATE.play) {
    $('#typing').attr('placeholder', appData.vm.strings.get('typeHere'));
    $('#typing').removeAttr('disabled');
    $('#typing').focus();
  }
  else {
    $('#typing').attr('disabled', 'disabled');
    $('#typing').attr('placeholder', '');
  }
};

App.prototype.startPlay = function() {
  let self = this;
  clearInterval(self.timerPlay);
  appData.vm.playState = STATE.play;
  self.updateTypingInput();
  appData.vm.elapsed.start = Date.now();
  appData.vm.elapsed.value = 0;
  self.timerPlay = setInterval(function() { self.timerPlayLoop(); }, 100);
};

App.prototype.finishPlay = function() {
  let self = this;

  appData.vm.playState = STATE.practiceFinished;
  self.updateTypingInput();
  appData.vm.saved.wpmHistory.unshift(appData.vm.getWPM());
  if (appData.vm.saved.wpmHistory.length > 20) {
    appData.vm.saved.wpmHistory.length = 20;
  }
  appData.vm.saved.wpmLevel = Math.floor(appData.vm.saved.wpmHistory.reduce((acc, c) => acc + c, 0) / appData.vm.saved.wpmHistory.length);
  appData.vm.save();

  clearInterval(self.timerPlay);

  tempTippy('#wpm', 'Your WPM score is ' + appData.vm.getWPM() + '. Good job!');
};

function tempTippy(selector, message, timeout) {
  let instances = tippy(selector, {
      content: '<span style="font-size: 200%;color: #1CD4DC;">' + message + '</span>',
      trigger: 'manual',
      theme: 'light',
      allowHTML: true,
      showOnCreate: true,
    });
  setTimeout(function() {
    instances.forEach(instance => {
      instance.destroy();
    });
  }, timeout ? timeout : 3000)  
}

function endsWith(s, suffix) {
    return s.indexOf(suffix, s.length - suffix.length) !== -1;
};

App.prototype.keyup = function(self, event) {
  if (appData.vm.playState === STATE.play) {
    var wordEl = $('#word' + self.currentWord);
    var wordText = wordEl.text();
    var inputText = $('#typing').val();
    var match = wordText.startsWith(inputText);
    
    if (match) {
      wordEl.removeClass('word-incorrect');
      wordEl.addClass('word-correct');
    }
    else {
      wordEl.removeClass('word-correct');
      wordEl.addClass('word-incorrect');
    }
    
    // Exact match then move to next word
    let isLastEqual = (self.currentWord === (appData.vm.words.length - 1)) && (wordText === inputText);
    let isSpacelessEqual = self.isSpaceless && (wordText === inputText);
    let isEqual = !self.isSpaceless && ((wordText + ' ') === inputText);
    if (isLastEqual || isSpacelessEqual || isEqual) {
      wordEl.removeClass('currentword');
      wordEl.removeClass('word-incorrect');
      wordEl.removeClass('word-correct');
      $('#typing').val('');
      self.currentWord++;
      appData.vm.wpmCharsCorrect += wordText.length;
      self.updatePercent(appData.vm.wpmCharsCorrect / appData.vm.totalCharacterCount);
      if (self.currentWord >= appData.vm.words.length) {
        self.finishPlay();
      }
      wordEl = $('#word' + self.currentWord);
      wordEl.addClass('currentword');
    }
  }
};

App.prototype.updatePercent = async function(pct) {
  let self = this;

  if (appData.vm.playMode === 'practice') {
    cars.update([{
      name: appData.vm.saved.name,
      carNum: self.carNum,
      uuid: appData.vm.saved.uuid,
      pct: pct
    }]);
  }
  else {
    try {
      let result = await wsCallAsync({
        url: '/game/update',
        data: {
          joinCode: appData.vm.joinCode,
          name: appData.vm.saved.name,
          carNum: self.carNum,
          uuid: appData.vm.saved.uuid,
          pct: pct
        }
      });
      try { self.handleClientEvent(result.data.data.clientEvent); } catch (e) {}

    }
    catch (result) {
    }
  }
};

App.prototype.timerPlayLoop = function() {
  let self = this;

  //if (appData.vm.playState === STATE.play) {
    let timerVal = Date.now() - appData.vm.elapsed.start;

    if ((appData.vm.playMode === 'practice') && (appData.vm.saved.timerVal !== 'none')) {
      if (appData.vm.saved.timerVal.substring(0, 1) === 's') {
        timerVal = parseInt(appData.vm.saved.timerVal.substring(1))*1000 - (Date.now() - appData.vm.elapsed.start);
      }
      else {
        timerVal = parseInt(appData.vm.saved.timerVal)*60*1000 - (Date.now() - appData.vm.elapsed.start);
      }
    }

    if (timerVal <= 0) {
      clearInterval(self.timerPlay);
      appData.vm.elapsed.text = '00:00';
      self.finishPlay();
    }
    else {
      appData.vm.elapsed.text = toHHMMSS(timerVal);
    }
  //}
};


var app = new App();

app.init();


export default app;
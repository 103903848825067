import appData from './appData';

var Dialog = {};

export default Dialog;

Dialog.defaultParams = {
  title: 'Info',
  message: ''
};

Dialog.show = function(options) {

  var params = JSON.parse(JSON.stringify(Dialog.defaultParams));

  options = options ? options : {};

  if (options.title) {
    params.title = options.title;
  }
  if (options.message) {
    params.message = options.message;
  }

  appData.vm.dialog.info.button2 = null;
  appData.vm.dialog.info.title = params.title;
  appData.vm.dialog.info.message = params.message;
  appData.vm.dialog.info.show = true;
};

Dialog.showDelete = function(options) {
  appData.vm.dialog.delete.text = '';
  appData.vm.dialog.delete.show = true;
  appData.vm.dialog.delete.textWarning = 'Click delete to continue';

  if (options && options.textWarning) {
    appData.vm.dialog.delete.textWarning = options.textWarning;
  }

  var deferDelete = $.Deferred();

  Vue.nextTick(function () {
    $('.modalInfo input').focus().keypress(function(event){
      var keycode = (event.keyCode ? event.keyCode : event.which);
      if (keycode == '13'){
        $('#dialogDeleteButton').click();
      }
    });
  });

  $(document).on('click', '#dialogDeleteButton', function(e) {
    e.preventDefault();

    if (!appData.vm.dialog.delete.requireInput || (appData.vm.dialog.delete.text.toLowerCase() === 'delete')) {
      appData.vm.dialog.delete.show = false;
      deferDelete.resolve();
    }
  });
  $(document).on('click', '.dialogDeleteCancel', function(e) {
    e.preventDefault();

    appData.vm.dialog.delete.show = false;
    deferDelete.reject();
  });

  return deferDelete;
};

Dialog.showInfo = function() {
  appData.vm.dialog.info.text = '';
  appData.vm.dialog.info.show = true;
  var defer = $.Deferred();

  $(document).on('click', '.dialogDone', function(e) {
    e.preventDefault();

    appData.vm.dialog.info.show = false;
    defer.resolve();
  });

  return defer;
};

Dialog.showInfoAsync = async function(message) {
  appData.vm.dialog.info.text = '';
  appData.vm.dialog.info.message = message;
  appData.vm.dialog.info.show = true;
  let promise = new Promise(function(resolve, reject) {

    $(document).on('click', '.dialogDone', function(e) {
      e.preventDefault();

      appData.vm.dialog.info.show = false;
      resolve();
    });
  });

  return promise;
};


import util from './util';

let speedy = [
  'Apache'
  , 'Bullet'
  , 'Buzz'
  , 'Comet'
  , 'Cougar'
  , 'Falcon'
  , 'Faster'
  , 'Flash'
  , 'Ghost'
  , 'Harley'
  , 'Jet'
  , 'Jump'
  , 'Jumping'
  , 'Lightning'
  , 'Miles'
  , 'Mustang'
  , 'Express'
  , 'Racer'
  , 'Rapid'
  , 'Rocket'
  , 'Sonic'
  , 'Speedy'
  , 'Speedster'
  , 'Taz'
  , 'Tornado'
  , 'Traveler'
  , 'Velocity'
  , 'Voyager'
  , 'Wild'
  , 'Wildfire'
];

let animal = [
  'Squirrel'
  , 'Chipmunk'
  , 'Porcupine'
  , 'Hedgehog'
  , 'Mongoose'
  , 'Meerkat'
  , 'Otter'
  , 'Raccoon'
  , 'Hyena'
  , 'Jackal'
  , 'Fox'
  , 'Monkey'
  , 'Panda'
  , 'Deer'
  , 'Wolf'
  , 'Kangaroo'
  , 'Koala'
  , 'Tiger'
  , 'Cheetah'
  , 'Bear'
  , 'Lion'
  , 'Zebra'
  , 'Possum'
  , 'Giraffe'
  , 'Wombat'
  , 'Elephant'
  , 'Chimpanzee'
  , 'Gorilla'
  , 'Bison'
  , 'Hippo'
  , 'Rhino'
  , 'Leopard'
  , 'Jaguar'
  , 'Cougar'
  , 'Puma'
];


function Names() {
}


Names.prototype.getName = function() {
  let list = util.getRandomInt(0, 1) === 0 ? speedy : animal;

  return list[util.getRandomInt(0, list.length-1)];
}

var names = new Names();

export default names;

import Polyglot from 'node-polyglot';
var jquery = require("/js/lib/jquery");

window.$ = window.jQuery = jquery;

let data = require('./strings.json');

// Extend all the languages to include English as the default
data.zh = $.extend(true, {}, data.en, data.zh);
data.fr = $.extend(true, {}, data.en, data.fr);
data.de = $.extend(true, {}, data.en, data.de)
data.hi = $.extend(true, {}, data.en, data.hi);
data.ko = $.extend(true, {}, data.en, data.ko);
data.es = $.extend(true, {}, data.en, data.es);


function Strings() {
  this.polyglot = new Polyglot();
  this.setLocale('en');
}

Strings.prototype.setLocale = function(locale) {
  this.polyglot.extend(data[locale]);
}

Strings.prototype.get = function(key, options) {
  return this.polyglot.t(key, options);
}

var strings = new Strings();

export default strings;


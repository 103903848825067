/* global $, app, Vue, DialogResponse, useRefreshToken, wsPrefix */

import config from './clientServerConfig.json';
import cookie from './cookie';
import DialogResponse from './dialogResponse';
import appData from './appData';

export default wsCall
export { wsCallAsync }

function wsCall(params, doNotRetry) {
  var deferWsCall = $.Deferred();
  var jwtAccess = cookie.get('jwtAccess');
  var urlWS = config.env[process.env.APPENV].urlWS;
  var wsPrefix = config.env[process.env.APPENV].wsPrefix;
  var url = wsPrefix + params.url;

  if (urlWS) {
    url = urlWS + wsPrefix + params.url;
  }

  if (jwtAccess) {
    jwtAccess = JSON.parse(jwtAccess);
  }

  $.ajax({
    method: "POST",
    url: url,
    data: JSON.stringify(params.data),
    contentType: "application/json; charset=utf-8",
    dataType: "json",
    cache: false,
    xhrFields: {
      withCredentials: true
    },
    crossDomain: true,
    beforeSend: function (xhr) {
      if (jwtAccess) {
        xhr.setRequestHeader ("authorization", "Bearer " + jwtAccess.token);
      }
    }
  }).done(function( data, textStatus, jqXHR ) {

    $('.is-loading').removeClass('is-loading');
    appData.vm.pageloaderVisible = false;

    deferWsCall.resolve(data, textStatus, jqXHR);

  }).fail(function( jqXHR, textStatus, errorThrown ) {
    $('.is-loading').removeClass('is-loading');
    appData.vm.pageloaderVisible = false;

    var defaultParams = {
      title: 'Error',
      message: 'An error occured. Try again later.',
    };

    // Try once more with refreshed token
    if (!doNotRetry && jqXHR.responseJSON && jqXHR.responseJSON.errorType && (jqXHR.responseJSON.errorType === "authenticationExpired")) {
      cookie.remove('jwtAccess');
      cookie.remove('jwtRefresh');

      window.location.href = '/login';
    }
    else {
      DialogResponse.show(jqXHR, defaultParams);

      deferWsCall.reject(jqXHR, textStatus, errorThrown);
    }
  });

  return deferWsCall;

}

async function wsCallAsync(params, doNotRetry) {
  let promise = new Promise(function(resolve, reject) {
    let deferWS = wsCall(params, doNotRetry);

    $.when(deferWS).done(function(data, textStatus, jqXHR) {
      resolve({
        data: data, 
        textStatus: textStatus, 
        jqXHR: jqXHR
      })
    }).fail(function( jqXHR, textStatus, errorThrown ) {
      reject({
        jqXHR: jqXHR, 
        textStatus: textStatus, 
        errorThrown: errorThrown
      })
    });
  });

  return promise;
}